import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = { expiresAt: String }
  static targets = ["display"]
  // 2025-02-01T00:00:00+10:00
  //
  connect() {
    this.interval = setInterval(() => this.calculate(), 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  calculate() {
    const now = new Date()
    const expiresAt = new Date(this.expiresAtValue)
    const timeRemaining = expiresAt - now
    const seconds = Math.floor(timeRemaining / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    const displaySeconds = seconds % 60
    const displayMinutes = minutes % 60
    const displayHours = hours % 24

    this.displayTarget.innerText = `${days}d ${displayHours}h ${displayMinutes}m ${displaySeconds}s`
  }
}
