import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static values = {
        hidden: true,
        formId: String,
    }
    connect() {
        let userId = ""
        try {
            userId = posthog.get_distinct_id()
        } catch (e) {
            console.error(e)
        }
        this.element.dataset.tallySrc = this.buildUrl(userId)
        this.hiddenValue = false
    }

    buildUrl(userId) {
        const params = new URLSearchParams({
            user_id: userId,
            alignLeft: 1,
            hideTitle: 1,
            transparentBackground: 1,
            dynamicHeight: 1,
        })
        return `https://tally.so/embed/${this.formIdValue}?${params}`
    }
}
