import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    #interval
    #direction = "forwards"

    connect() {
        this.start()
        this.element.addEventListener("mouseover", () => this.stop())
        this.element.addEventListener("mouseout", () => this.start())
    }

    stop() {
        clearInterval(this.#interval)
    }

    start() {
        if (window.innerWidth <= 640 || this.#isNotMobileDevice()) return
        this.#interval = setInterval(() => this.scroll(), 40)
    }

    disconnect() {
        this.stop()
        this.element.removeEventListener("mouseover", () => this.stop())
        this.element.removeEventListener("mouseout", () => this.start())
    }

    scroll() {
        if (this.atEnd) this.#direction = "backwards"
        if (this.atBeginning) this.#direction = "forwards"

        if (this.#direction === "backwards") {
            this.element.scrollLeft -= 1
        } else {
            this.element.scrollLeft += 1
        }
    }

    get atEnd() {
        return (
            this.element.scrollLeft + this.element.clientWidth >=
                this.element.scrollWidth || this.element.scrollLeft <= 0
        )
    }

    get atBeginning() {
        return this.element.scrollLeft <= 0
    }

    #isNotMobileDevice() {
        const userAgent =
            navigator.userAgent || navigator.vendor || window.opera

        // Regular expressions to match common mobile user agents
        const mobileRegex =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

        // Test the user agent string against the regex
        return !mobileRegex.test(userAgent)
    }
}
