import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["title", "body", "quote", "dialog", "quoteAuthor"]

    open(values) {
        const { title, body, quote, quoteAuthor } = values ?? {}
        if (title) this.titleTarget.textContent = title
        if (body) this.bodyTarget.textContent = body
        if (quote) this.quoteTarget.textContent = quote
        if (quoteAuthor) this.quoteAuthorTarget.textContent = `- ${quoteAuthor}`
        this.dialogTarget.showModal()
    }

    clearValues() {
        if (this.hasTitleTarget) this.titleTarget.textContent = ""
        if (this.hasBodyTarget) this.bodyTarget.textContent = ""
        if (this.hasQuoteTarget) this.quoteTarget.textContent = ""
        if (this.hasQuoteAutherTarget) this.quoteAuthorTarget.textContent = ""
    }

    close() {
        this.clearValues()
        this.dialogTarget.close()
    }
}
