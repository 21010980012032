
        import * as module0 from './controllers/animate_scroll_controller.js';import * as module1 from './controllers/countdown_controller.js';import * as module2 from './controllers/modal_button_controller.js';import * as module3 from './controllers/modal_controller.js';import * as module4 from './controllers/nav_controller.js';import * as module5 from './controllers/review_controller.js';import * as module6 from './controllers/tally_controller.js'
        const modules = {
            "./controllers/animate_scroll_controller.js": module0,
            "./controllers/countdown_controller.js": module1,
            "./controllers/modal_button_controller.js": module2,
            "./controllers/modal_controller.js": module3,
            "./controllers/nav_controller.js": module4,
            "./controllers/review_controller.js": module5,
            "./controllers/tally_controller.js": module6,
        };
        export default modules;
      